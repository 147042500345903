import clsx from 'clsx';
import React, { useMemo } from 'react';

import { FormikField } from '../Formik';

import './RadioField.css';

type RadioFieldProps = JSX.IntrinsicElements['input'] &
  FormikField & {
    id: string;
    touched?: boolean;
    error?: string;
  };

export const RadioField = React.memo<RadioFieldProps>(({ className, touched, error, style, children, ...rest }) => {
  const { field, meta, form, ...radioProps } = rest;
  const isChecked = useMemo(
    () => radioProps.checked || field?.value === radioProps.value,
    [field?.value, radioProps.checked, radioProps.value]
  );
  const BEM = clsx('radio-field', className, {
    'radio-field--checked': isChecked,
  });

  return (
    <div className={BEM} style={style}>
      <div className="radio-field__icon">
        <input className="radio-field__input" {...field} {...radioProps} type="radio" />
      </div>
      <label className="radio-field__title" htmlFor={radioProps.id}>
        {children}
      </label>
    </div>
  );
});
