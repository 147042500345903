import sortBy from 'lodash-es/sortBy';
import React from 'react';
import { useDispatch } from 'react-redux';

import { NAV_ROUTES } from '../../Routes';
import {
  useGetKnowledgeCenterLinksQuery,
  useGetOfficePendingCountQuery,
  useGetToolsQuery,
  useGetUsefulLinksQuery,
  useGetUserPendingCountQuery,
} from '../../store/api/generalApi';
import { useAuthState, useLogout } from '../../store/auth/authHooks';
import { toggleMenuVisibility } from '../../store/general/generalStore';
import { MANAGER_ROLES } from '../../utils/permissions';
import { useRoleGuard } from '../../utils/roleGuard';
import { Button } from '../button/Button';
import { Card } from '../card/Card';
import { CardBody } from '../card/components/CardBody';
import { IconExit, IconSquares } from '../icon';
import { IconItem } from '../item/IconItem';
import { MemberItem } from '../item/MemberItem';
import { NumberItem } from '../item/NumberItem';
import { NotificationBubble } from '../notification-bubble/NotificationBubble';
import { GroupCard } from './components/group-card/GroupCard';

interface MenuProps {}

export const Menu: React.FC<MenuProps> = React.memo(() => {
  const dispatch = useDispatch();
  const { data: knowledge } = useGetKnowledgeCenterLinksQuery({ limit: 5 }, { refetchOnMountOrArgChange: true });
  const { data: links } = useGetUsefulLinksQuery({ limit: 6 }, { refetchOnMountOrArgChange: true });
  const { data: tools } = useGetToolsQuery(undefined, { refetchOnMountOrArgChange: true });
  const { roleBasedCountry } = useAuthState();
  const { data: pendingProfiles } = useGetUserPendingCountQuery(
    { country: roleBasedCountry },
    { refetchOnMountOrArgChange: true }
  );
  const { data: pendingOffices } = useGetOfficePendingCountQuery(
    { country: roleBasedCountry },
    { refetchOnMountOrArgChange: true }
  );
  const hasAccessToPendingRequests = useRoleGuard(MANAGER_ROLES);
  const { user } = useAuthState();
  const logout = useLogout();

  return (
    <div className="overflow-auto max-h-container flex-1 px-6 lg:px-16 pb-16 w-full bg-white z-10">
      <div className="max-w-260 mx-auto flex flex-col">
        <div className="hidden md:flex justify-between pt-10 pb-7">
          <Button variant="link-secondary" href={NAV_ROUTES.DASHBOARD} onClick={() => dispatch(toggleMenuVisibility())}>
            ← Back to the dashboard
          </Button>
          <Button variant="link-secondary" className="uppercase" onClick={() => dispatch(toggleMenuVisibility())}>
            X Close menu
          </Button>
        </div>

        <div className="md:hidden mt-6 md:mt-0">
          <Button
            variant="secondary"
            className="w-full"
            iconLeft={<IconSquares />}
            href={NAV_ROUTES.DASHBOARD}
            onClick={() => dispatch(toggleMenuVisibility())}
          >
            Go to dashboard
          </Button>
        </div>
        <div className="md:flex mt-6 md:mt-0">
          <GroupCard
            title="Eurodesk tools"
            text="Select a menu point from the right and explore MyEurodesk"
            className="md:bg-tools-img"
          />
          <div className="grid grid-cols-3 md:grid-cols-auto-fit-64 gap-6 flex-1 items-start">
            {tools?.map(({ id, title, links, logoUrl, slug }) => (
              <Button
                variant="plain"
                href={slug}
                key={id}
                className="relative w-24 md:w-auto mx-auto md:mx-0"
                onClick={() => dispatch(toggleMenuVisibility())}
              >
                {hasAccessToPendingRequests &&
                slug.includes(NAV_ROUTES.PROFILE({ child: 'overview' })) &&
                (pendingProfiles?.pending || pendingOffices?.pending) ? (
                  <NotificationBubble className="hidden md:flex absolute -top-1 -right-1">{`${
                    Number(pendingProfiles?.pending || 0) + Number(pendingOffices?.pending || 0)
                  }`}</NotificationBubble>
                ) : null}

                <Card className="md:h-full border-0 md:border-2 rounded-full md:rounded-lg bg-primary-gradient md:bg-none">
                  <CardBody className="flex flex-col justify-center items-center h-24">
                    <span className="sr-only">{title}</span>
                    <img src={logoUrl} alt={title} className="h-auto md:h-24" />
                  </CardBody>
                </Card>
              </Button>
            ))}
          </div>
        </div>

        <hr className="my-8 invisible md:visible" />

        <div className="md:flex">
          <GroupCard
            title="Knowledge centre"
            text="Knowledge Centre: Expand your knowledge about Eurodesk"
            className="md:bg-knowledge-img"
          />
          <div className="grid grid-cols-auto-fit-64 gap-6 flex-1">
            {sortBy(knowledge || [], 'weight').map(({ title, slug, id }, index) => (
              <Button
                variant="plain"
                href={NAV_ROUTES.KNOWLEDGE_CENTRE({ child: 'topic', id: id })}
                key={id}
                className="relative"
                onClick={() => dispatch(toggleMenuVisibility())}
              >
                <NumberItem number={index + 1}>{title} →</NumberItem>
              </Button>
            ))}
            <Button
              href={NAV_ROUTES.KNOWLEDGE_CENTRE({ child: 'overview' })}
              onClick={() => dispatch(toggleMenuVisibility())}
              variant="primary"
            >
              Explore more topics
            </Button>
          </div>
        </div>

        <hr className="my-8 invisible md:visible" />

        <div className="md:flex">
          <GroupCard
            title="Useful links"
            text="Useful resources to help you in your work"
            className="md:bg-useful-links-img"
          />
          <div className="grid grid-cols-auto-fit-64 gap-6 flex-1">
            {sortBy(links || [], 'weight').map(({ id, link }) => (
              <Button
                variant="plain"
                href={link?.linkUrl}
                key={id}
                className="relative"
                onClick={() => dispatch(toggleMenuVisibility())}
              >
                <IconItem
                  icon="chain"
                  title={`${link?.title} →`}
                  info={(link?.linkUrl || '').replace(/^https?:\/\//, '')}
                />
              </Button>
            ))}
          </div>
        </div>

        <div className="md:invisible">
          <hr className="my-8" />

          <MemberItem info={user?.name || ''} name="You are logged in as" image={user?.image} />

          <hr className="my-8 md:invisible" />

          <Button variant="link" onClick={logout} iconLeft={<IconExit />}>
            Log out
          </Button>
        </div>
      </div>
    </div>
  );
});
