import clsx from 'clsx';
import React from 'react';

export interface CardBodyProps {
  className?: string;
}

export const CardBody: React.FC<CardBodyProps> = ({ children, className }) => {
  const BEM = clsx('w-full p-4', className);
  return <div className={BEM}>{children}</div>;
};
