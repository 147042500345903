import { combineReducers } from '@reduxjs/toolkit';

import { authenticationApi } from './store/api/authenticationApi';
import { generalApi } from './store/api/generalApi';
import generalStore from './store/general/generalStore';

const rootReducer = combineReducers({
  general: generalStore,
  [generalApi.reducerPath]: generalApi.reducer,
  [authenticationApi.reducerPath]: authenticationApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
