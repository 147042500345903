import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { ROLE } from '../../utils/permissions';
import { authenticationApi } from '../api/authenticationApi';
import { generalApi, OFFICE_STATUS, USER_STATUS, UserProfileDTO, useWhoAmIQuery } from '../api/generalApi';
import { toggleMenuVisibility } from '../general/generalStore';

// helper hook to return authentication state
export const useAuthState = () => {
  const isAdminEbl = (user?: UserProfileDTO) => user?.roles?.some((r) => [ROLE.ADMIN, ROLE.EBL].includes(r));

  return useWhoAmIQuery(undefined, {
    selectFromResult: ({ data, isLoading }) => {
      return {
        user: data,
        roleBasedCountry: !isAdminEbl(data) ? data?.office?.address?.countryCode : undefined, // for Admin/EBL the country shouldn't matter
        office: data?.office?.id ? data?.office : undefined,
        isAuthenticated: !!data?.id,
        allowedAppAccess:
          data?.userStatus &&
          [USER_STATUS.APPROVED, USER_STATUS.HIDDEN].includes(data?.userStatus) &&
          data?.office?.status &&
          [OFFICE_STATUS.APPROVED, OFFICE_STATUS.HIDDEN].includes(data?.office?.status),
        isLoading: isLoading,
      };
    },
  });
};

export const useLogout = () => {
  const dispatch = useDispatch();

  return useCallback(async () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('expires_in');
    localStorage.removeItem('refresh_token');

    dispatch(generalApi.util.resetApiState());
    dispatch(authenticationApi.util.resetApiState());
    dispatch(toggleMenuVisibility(false));

    dispatch(generalApi.endpoints.whoAmI.initiate(undefined, { forceRefetch: true }));
    dispatch(generalApi.endpoints.getPermissions.initiate(undefined, { forceRefetch: true }));
  }, [dispatch]);
};
