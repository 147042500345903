import clsx from 'clsx';
import { getIn } from 'formik';
import React from 'react';

import { FieldLabel, FieldLabelProps } from '../field-label/FieldLabel';
import { FormikField } from '../Formik';

import './TextField.css';

export type TextFieldProps = FieldLabelProps &
  JSX.IntrinsicElements['input'] &
  JSX.IntrinsicElements['textarea'] &
  FormikField & {
    id: string;
    multiline?: boolean;
  };

export const TextField = React.forwardRef<any, TextFieldProps>((props, ref) => {
  const { multiline, title, subTitle, touched, error, className, style, ...rest } = props;
  const { field, meta, form, ...inputProps } = rest;
  const Tag = multiline || rest.rows ? 'textarea' : 'input';

  const name = inputProps?.name || field?.name || '';
  const isTouched = touched || meta?.touched || getIn(form?.touched, name);
  const isError = error || meta?.error || getIn(form?.errors, name);

  return (
    <FieldLabel
      id={inputProps.id}
      title={title}
      subTitle={subTitle}
      required={inputProps.required}
      touched={isTouched}
      error={isError}
      className={className}
      style={style}
    >
      <Tag
        className={clsx('text-field', { 'text-field--invalid': isTouched && isError })}
        type="text"
        ref={ref}
        {...field}
        {...inputProps}
      />
    </FieldLabel>
  );
});
