import difference from 'lodash-es/difference';
import uniq from 'lodash-es/uniq';
import { useMemo } from 'react';

import { useGetPermissionsQuery } from '../store/api/generalApi';
import { useAuthState } from '../store/auth/authHooks';
import { PERMISSION, ROLE } from './permissions';

export enum ROLES_MATCH_TYPE {
  INCLUDES_ALL = 'INCLUDES_ALL', // user must have all the roles/permissions
  INCLUDE_ONE = 'INCLUDE_ONE', // user must have at least one of the roles/permissions
  EXACT = 'EXACT', // user must have only these roles/permissions
}

/**
 * Guard that determines if the user has the correct roles to satisfy matchRoles by matchType
 * @param matchRoles - array or ROLES we want to match against
 * @param matchType - different matching methods (default is INCLUDE_ONE)
 * @param not - if we want to flip the return value
 * @param includeAdmin - if admin should have access by default
 */
export const useRoleGuard = (
  matchRoles: ROLE[] = [],
  matchType: ROLES_MATCH_TYPE = ROLES_MATCH_TYPE.INCLUDE_ONE,
  not?: boolean,
  includeAdmin: boolean = false
): boolean => {
  const { user } = useAuthState();
  const enhancedMatchRoles = includeAdmin ? [...matchRoles, ROLE.ADMIN] : matchRoles;
  const roles = user?.roles || [];
  let returnValue = false;

  if (roles?.length) {
    switch (matchType) {
      case ROLES_MATCH_TYPE.INCLUDES_ALL:
        returnValue = difference(enhancedMatchRoles, roles).length === 0;
        break;
      case ROLES_MATCH_TYPE.INCLUDE_ONE:
        returnValue = difference(roles, enhancedMatchRoles).length < roles.length;
        break;
      case ROLES_MATCH_TYPE.EXACT:
        returnValue =
          difference(roles, enhancedMatchRoles).length === 0 && difference(enhancedMatchRoles, roles).length === 0;
        break;
      default:
        break;
    }
  }

  return not ? !returnValue : returnValue;
};

/**
 * Guard that determines if the user has the correct permissions to satisfy matchPermissions by matchType
 * @param matchPermissions - array or PERMISSIONS we want to match against
 * @param matchType - different matching methods (default is INCLUDE_ONE)
 * @param not - if we want to flip the return value
 */
export const usePermissionGuard = (
  matchPermissions: PERMISSION[] = [],
  matchType: ROLES_MATCH_TYPE = ROLES_MATCH_TYPE.INCLUDE_ONE,
  not?: boolean
): boolean => {
  const { user } = useAuthState();
  const { data: permissionsJson } = useGetPermissionsQuery(undefined);
  let returnValue = false;

  const roles: ROLE[] = useMemo(() => user?.roles || [], [user?.roles]);

  const permissions: PERMISSION[] = useMemo(
    () =>
      permissionsJson
        ? uniq(
            roles.reduce<PERMISSION[]>((prevValue, currentValue) => {
              return [...prevValue, ...(permissionsJson[currentValue] || [])];
            }, [])
          )
        : [],
    [permissionsJson, roles]
  );

  // admin should get access for all the permission keys
  // only when user is just admin give the rights to all parts of the app
  if (roles.includes(ROLE.ADMIN) && !roles.includes(ROLE.EBL)) {
    return !not;
  }

  if (permissions?.length) {
    switch (matchType) {
      case ROLES_MATCH_TYPE.INCLUDES_ALL:
        returnValue = difference(matchPermissions, permissions).length === 0;
        break;
      case ROLES_MATCH_TYPE.INCLUDE_ONE:
        returnValue = difference(permissions, matchPermissions).length < permissions.length;
        break;
      case ROLES_MATCH_TYPE.EXACT:
        returnValue =
          difference(permissions, matchPermissions).length === 0 &&
          difference(matchPermissions, permissions).length === 0;
        break;
      default:
        break;
    }
  }

  return not ? !returnValue : returnValue;
};
