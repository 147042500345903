/*
 * Item component is only used as base for larger components. Avoid using Item component inside views.
 */

import clsx from 'clsx';
import React from 'react';

import './Item.css';

interface ItemProps {
  className?: string;
}

export const Item: React.FC<ItemProps> = ({ children, className }) => {
  const BEM = clsx('item', className);

  return <div className={BEM}>{children}</div>;
};

export const ItemAside: React.FC<ItemProps> = ({ children, className }) => {
  const BEM = clsx('flex-grow-0 flex-shrink-0 mr-4 ', className);
  return <div className={BEM}>{children}</div>;
};

export const ItemBody: React.FC = ({ children }) => {
  return <div className="flex-1">{children}</div>;
};
