import React from 'react';
import { generatePath } from 'react-router-dom';

import { MANAGER_ROLES, PERMISSION, ROLE } from './utils/permissions';
import { AcademyRouteProps } from './views/academy/Academy';
import { CompetenceFrameworkRouteProps } from './views/competence-framework/CompetenceFrameWork';
import { ToolsPageProps } from './views/dynamic-tool/DynamicTool';
import { EmbedToolsRouteProps } from './views/embed-tools/EmbedTools';
import { EuroclassesRouteProps } from './views/euroclasses/Euroclasses';
import { EuropeanYouthPortalRouteProps } from './views/european-youth-portal/EuropeanYouthPortal';
import { KnowledgeCentreRouteProps } from './views/knowledge-centre/KnowledgeCentre';
import { ProfileRouteProps } from './views/profile/Profile';
import { ProgrammeDatabaseRouteProps } from './views/programme-database/ProgrammeDatabase';
import { PublicationsRouteProps } from './views/publications/Publications';
import { QualifyingTrainingProgrammeRouteProps } from './views/qualifying-training-programme/QualifyingTrainingProgramme';
import { SettingsRouteProps } from './views/settings/Settings';
import { TTMEventManagerRouteProps } from './views/ttm-event-manager/TTMEventManager';
import { UnauthorizedRouteProps } from './views/unauthorized/Unauthorized';

// For generating routes and checking if route exists
export enum ROUTES {
  DASHBOARD = '/',
  ACADEMY = '/academy/:child?/:id?',
  PUBLICATIONS = '/publications/:child?/:id?',
  EYP = '/european-youth-portal/:child?',
  QTP = '/qualifying-training-programme/:child?',
  EUROCLASSES = '/euroclasses/:child?',
  KNOWLEDGE_CENTRE = '/knowledge-centre/:child?/:id?',
  UNAUTHORIZED = '/(user)?/:child?/:uid?/:timestamp?/:hash?',
  PROFILE = '/profile/:child?/:id?',
  TTM_EVENT_MANAGER = '/ttm-event-manager/:child?/:id?',
  EMBED_TOOLS = '/embed-tools/:child?',
  COMPETENCE_FRAMEWORK = '/competence-framework/:child?',
  PROGRAMME_DATABASE = '/programme-database/:child?/:id?/:language?',
  SETTINGS = '/settings/:child?/:id?',
  PAGE_NOT_FOUND = '/page-not-found',
  DYNAMIC_TOOL = '/tools/:id?/:child?',
  BE_NODE = '/node/:id?',
}

// For getting navigable route - for links and/or navigating programmatically
export const NAV_ROUTES = {
  DASHBOARD: ROUTES.DASHBOARD,
  ACADEMY: (p?: ToStringableValues<AcademyRouteProps>) => generatePath(ROUTES.ACADEMY, p),
  PUBLICATIONS: (p?: ToStringableValues<PublicationsRouteProps>) => generatePath(ROUTES.PUBLICATIONS, p),
  EYP: (p?: ToStringableValues<EuropeanYouthPortalRouteProps>) => generatePath(ROUTES.EYP, p),
  QTP: (p?: ToStringableValues<QualifyingTrainingProgrammeRouteProps>) => generatePath(ROUTES.QTP, p),
  EUROCLASSES: (p?: ToStringableValues<EuroclassesRouteProps>) => generatePath(ROUTES.EUROCLASSES, p),
  KNOWLEDGE_CENTRE: (p?: ToStringableValues<KnowledgeCentreRouteProps>) => generatePath(ROUTES.KNOWLEDGE_CENTRE, p),
  UNAUTHORIZED: (p?: ToStringableValues<UnauthorizedRouteProps>) => generatePath(ROUTES.UNAUTHORIZED, p),
  PROFILE: (p?: ToStringableValues<ProfileRouteProps>) => generatePath(ROUTES.PROFILE, p),
  TTM_EVENT_MANAGER: (p?: ToStringableValues<TTMEventManagerRouteProps>) => generatePath(ROUTES.TTM_EVENT_MANAGER, p),
  EMBED_TOOLS: (p?: ToStringableValues<EmbedToolsRouteProps>) => generatePath(ROUTES.EMBED_TOOLS, p),
  COMPETENCE_FRAMEWORK: (p?: ToStringableValues<CompetenceFrameworkRouteProps>) =>
    generatePath(ROUTES.COMPETENCE_FRAMEWORK, p),
  PROGRAMME_DATABASE: (p?: ToStringableValues<ProgrammeDatabaseRouteProps>) =>
    generatePath(ROUTES.PROGRAMME_DATABASE, p),
  SETTINGS: (p?: ToStringableValues<SettingsRouteProps>) => generatePath(ROUTES.SETTINGS, p),
  DYNAMIC_TOOL: (p?: ToStringableValues<ToolsPageProps>) => generatePath(ROUTES.DYNAMIC_TOOL, p),
  PAGE_NOT_FOUND: ROUTES.PAGE_NOT_FOUND,
};

const AsyncDashboardViewWrapper = React.lazy(() => import('./views/dashboard/Dashboard'));
const AsyncAcademyViewWrapper = React.lazy(() => import('./views/academy/Academy'));
const AsyncPublicationsViewWrapper = React.lazy(() => import('./views/publications/Publications'));
const AsyncEYPViewWrapper = React.lazy(() => import('./views/european-youth-portal/EuropeanYouthPortal'));
const AsyncQTPViewWrapper = React.lazy(
  () => import('./views/qualifying-training-programme/QualifyingTrainingProgramme')
);
const AsyncEuroclassesViewWrapper = React.lazy(() => import('./views/euroclasses/Euroclasses'));
const AsyncKnowledgeCentreViewWrapper = React.lazy(() => import('./views/knowledge-centre/KnowledgeCentre'));
const AsyncProgrammeDatabaseViewWrapper = React.lazy(() => import('./views/programme-database/ProgrammeDatabase'));
const AsyncProfileViewWrapper = React.lazy(() => import('./views/profile/Profile'));
const AsyncTTMEventManagerViewWrapper = React.lazy(() => import('./views/ttm-event-manager/TTMEventManager'));
const AsyncEmbedToolsViewWrapper = React.lazy(() => import('./views/embed-tools/EmbedTools'));
const AsyncCompetenceFrameworkViewWrapper = React.lazy(
  () => import('./views/competence-framework/CompetenceFrameWork')
);
const AsyncSettingsViewWrapper = React.lazy(() => import('./views/settings/Settings'));
const AsyncDynamicToolViewWrapper = React.lazy(() => import('./views/dynamic-tool/DynamicTool'));
const AsyncPageNotFoundViewWrapper = React.lazy(() => import('./views/page-not-found/PageNotFound'));

export interface RoutesWithComponentsProps<ROUTE_ENUM> {
  path: ROUTE_ENUM;
  sensitive: boolean;
  component: React.LazyExoticComponent<() => JSX.Element>;
  /**
   * If route is allowed for only specific roles
   */
  roleLock?: {
    roles: ROLE[];
    /**
     * Default fallback route is root(/)
     */
    fallbackRoute?: string;
  };
  /**
   * If route is allowed for only specific roles
   */
  permissionLock?: {
    permissions: PERMISSION[];
    /**
     * Default fallback route is root(/)
     */
    fallbackRoute?: string;
  };
}

export const RoutesWithComponents: RoutesWithComponentsProps<ROUTES>[] = [
  { path: ROUTES.DASHBOARD, sensitive: true, component: AsyncDashboardViewWrapper },
  { path: ROUTES.ACADEMY, sensitive: true, component: AsyncAcademyViewWrapper },
  { path: ROUTES.PUBLICATIONS, sensitive: true, component: AsyncPublicationsViewWrapper },
  { path: ROUTES.EYP, sensitive: true, component: AsyncEYPViewWrapper },
  { path: ROUTES.QTP, sensitive: true, component: AsyncQTPViewWrapper },
  { path: ROUTES.EUROCLASSES, sensitive: true, component: AsyncEuroclassesViewWrapper },
  { path: ROUTES.KNOWLEDGE_CENTRE, sensitive: true, component: AsyncKnowledgeCentreViewWrapper },
  { path: ROUTES.PROGRAMME_DATABASE, sensitive: true, component: AsyncProgrammeDatabaseViewWrapper },
  { path: ROUTES.PROFILE, sensitive: true, component: AsyncProfileViewWrapper },
  { path: ROUTES.TTM_EVENT_MANAGER, sensitive: true, component: AsyncTTMEventManagerViewWrapper },
  { path: ROUTES.EMBED_TOOLS, sensitive: false, component: AsyncEmbedToolsViewWrapper },
  { path: ROUTES.COMPETENCE_FRAMEWORK, sensitive: false, component: AsyncCompetenceFrameworkViewWrapper },
  {
    path: ROUTES.SETTINGS,
    sensitive: false,
    component: AsyncSettingsViewWrapper,
    roleLock: {
      roles: MANAGER_ROLES,
    },
  },
  { path: ROUTES.DYNAMIC_TOOL, sensitive: false, component: AsyncDynamicToolViewWrapper },
  { path: ROUTES.PAGE_NOT_FOUND, sensitive: false, component: AsyncPageNotFoundViewWrapper },
];
