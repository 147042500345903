import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import I18NextHttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export const I18N_LANGUAGES = {
  EN: 'en',
};

export const langUrlBase = '/:lang?';

export const I18N_NAMESPACES = {
  GENERAL: 'general',
  UNAUTHORIZED: 'unauthorized',
  DASHBOARD: 'dashboard',
  ACADEMY: 'academy',
  KNOWLEDGE_CENTRE: 'knowledge-centre',
  EUROCLASSES: 'euroclasses',
  EMBED_TOOLS: 'embed-tools',
  COMPETENCE_FRAMEWORK: 'competence-framework',
  PROGRAMME_DATABASE: 'programme-database',
  SETTINGS: 'settings',
  PROFILE: 'profile',
  TTM_EVENT_MANAGER: 'ttm-event-manager',
  LANGUAGES: 'languages',
  PUBLICATIONS: 'publications',
  EYP: 'european-youth-portal',
  QTP: 'qualifying-training-programme',
};

i18next.on('languageChanged', function (language) {
  localStorage.setItem('language', language);
});

i18next
  .use(I18NextHttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false, //process.env.NODE_ENV !== 'production',
    backend: { loadPath: '/locales/{{lng}}/{{ns}}.json' },
    ns: Object.values(I18N_NAMESPACES),
    defaultNS: I18N_NAMESPACES.GENERAL,
    lng: 'en',
    fallbackLng: false,
    whitelist: Object.values(I18N_LANGUAGES),
    interpolation: { escapeValue: false },
    keySeparator: false,
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['p'],
    },
  });

export default i18next;
