import clsx from 'clsx';
import React from 'react';

import './Heading.css';

interface HeadingProps {
  is?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  className?: string;
}

export const Heading: React.FC<HeadingProps> = ({ is = 'h1', as, className, children }) => {
  const BEM = clsx(as ?? is, className);
  const Component = is;
  return <Component className={BEM}>{children}</Component>;
};
