import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ErrorBoundary } from './components/error-boundary/ErrorBoundary';
import { RouteGuard } from './components/guard/GuardComponent';
import { Loader } from './components/loader/Loader';
import { Menu } from './components/menu/Menu';
import { Navigation } from './components/navigation/Navigation';
import Sidebar from './components/sidebar/Sidebar';
import { langUrlBase } from './i18n';
import { ROUTES, RoutesWithComponents } from './Routes';
import { useWhoAmIQuery } from './store/api/generalApi';
import { useAuthState, useLogout } from './store/auth/authHooks';
import { selectMenuVisibility } from './store/general/generalSelectors';
import NoAccess from './views/unauthorized/components/no-access/NoAccess';

const Unauthorized = React.lazy(() => import('./views/unauthorized/Unauthorized'));

export default function Root() {
  const menuVisibility = useSelector(selectMenuVisibility);
  const { isLoading: isUserLoading, error } = useWhoAmIQuery();
  const { isAuthenticated, allowedAppAccess, isLoading: isAuthLoading } = useAuthState();
  const logout = useLogout();

  useEffect(() => {
    if (error && (error as any)?.originalStatus === 401) {
      const refresh_token = localStorage.getItem('refresh_token');

      if (refresh_token || isAuthenticated) {
        toast.error('Account inactive. Contact administrator for more info.');
        logout();
      }
    }
  }, [error, isAuthenticated, logout]);

  const renderSwitch = useMemo(
    () => (
      <Switch>
        {RoutesWithComponents.map((route, index) => (
          <Route
            path={`${langUrlBase}${route.path}`}
            exact
            sensitive={route.sensitive}
            key={index}
            render={(props) => <RouteGuard {...props} route={route} />}
          />
        ))}
        {/* Fallback when slug alias is not correctly set on BE, then redirect the user to Drupal UI */}
        <Route
          path={`${langUrlBase}${ROUTES.BE_NODE}`}
          exact
          render={() => <Redirect to={`${process.env.REACT_APP_API_URL}/${window.location.pathname}`} />}
        />
        <Redirect to={ROUTES.PAGE_NOT_FOUND} />
      </Switch>
    ),
    []
  );

  const renderUnauthorizedSwitch = useMemo(
    () => (
      <Switch>
        <Route path={`${langUrlBase}${ROUTES.UNAUTHORIZED}`}>
          <Unauthorized />
        </Route>
      </Switch>
    ),
    []
  );

  const renderWrapper = useCallback(
    (content) => (
      <React.Suspense fallback={<Loader fullPage size="lg" />}>
        <ErrorBoundary>{content}</ErrorBoundary>
      </React.Suspense>
    ),
    []
  );

  return (
    <ErrorBoundary>
      <main className="flex flex-1 flex-col">
        <Helmet>
          <body className={clsx({ 'overflow-hidden': menuVisibility })} />
        </Helmet>
        <Navigation isAuthenticated={isAuthenticated} />
        <div className="flex flex-1">
          {isUserLoading || isAuthLoading ? (
            <Loader fullPage size="lg" />
          ) : isAuthenticated ? (
            <>
              <Sidebar />
              <div className="relative w-full overflow-auto max-h-container print:max-h-full">
                {allowedAppAccess ? (
                  <>
                    {menuVisibility && <Menu />}
                    <div
                      className={clsx('flex', {
                        hidden: menuVisibility,
                      })}
                    >
                      {renderWrapper(renderSwitch)}
                    </div>
                  </>
                ) : (
                  renderWrapper(<NoAccess />)
                )}
              </div>
            </>
          ) : (
            renderWrapper(renderUnauthorizedSwitch)
          )}
        </div>
      </main>
    </ErrorBoundary>
  );
}
