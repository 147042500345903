import clsx from 'clsx';
import React, { useMemo } from 'react';

import { IconChecked } from '../../icon';
import { FormikField } from '../Formik';

import './CheckField.css';

type CheckFieldProps = JSX.IntrinsicElements['input'] &
  FormikField & {
    id: string;
    touched?: boolean;
    error?: string;
    horizontal?: boolean;
  };

export const CheckField = React.memo<CheckFieldProps>(({ touched, error, className, style, children, ...rest }) => {
  const { field, meta, form, ...checkProps } = rest;

  const isChecked = useMemo(
    () => checkProps.checked || field?.value?.includes(checkProps.value),
    [field?.value, checkProps.checked, checkProps.value]
  );

  const BEM = clsx('check-field', 'check-field--horizontal', className);

  return (
    <div className={BEM} style={style}>
      <input className="check-field__input" {...field} {...checkProps} type="checkbox" />
      <div className="check-field__icon">
        {isChecked && <IconChecked size="1rem" className="check-field__icon-checked" />}
      </div>
      <label className="check-field__title" htmlFor={checkProps.id}>
        {children}
      </label>
    </div>
  );
});
