import { toLower } from 'lodash-es';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonSelectOption } from '../components/form/select-field/SelectField';
import { I18N_NAMESPACES } from '../i18n';
import {
  GetOfficesArgs,
  PROGRAMME_STATUS,
  useGetBulletinCategoriesQuery,
  useGetCountriesFunctionsQuery,
  useGetCountriesQuery,
  useGetEventFormatOfActivityQuery,
  useGetEventFormatsQuery,
  useGetEventStatusesQuery,
  useGetEventTopicOfActivityQuery,
  useGetEventTypeOfActivityQuery,
  useGetEventTypesQuery,
  useGetEventYouthGoalOfActivityQuery,
  useGetOfficesQuery,
  useGetProgrammeCategoriesQuery,
  useGetProgrammeTargetQuery,
  useGetProgrammeTopicsQuery,
  useGetProgrammeTypeOfActivityQuery,
  useGetProgrammeTypeQuery,
  USER_STATUS,
} from '../store/api/generalApi';

interface RawLanguages {
  [key: string]: {
    name: string;
    nativeName: string;
  };
}

export const useLanguagesOptions = () => {
  const { t } = useTranslation([I18N_NAMESPACES.LANGUAGES]);

  const rawLanguages: RawLanguages = useMemo(() => t('languages:list', { returnObjects: true }) || {}, [t]);
  const languages = useMemo(
    () => Object.keys(rawLanguages).map((key) => ({ value: key, label: rawLanguages[key].name })),
    [rawLanguages]
  );

  return languages;
};

export const useCountryLanguageOptions = (country: string) => {
  const { data } = useGetCountriesFunctionsQuery();
  const languages = data?.find((i) => toLower(i.code) === toLower(country))?.languages || {};
  return Object.entries(languages)?.map(([key, value]) => ({ value: key, label: value }));
};

export const useOfficeTypeOptions = (otherOptions: CommonSelectOption[] = []): CommonSelectOption[] => {
  return [...otherOptions];
};

export const useStatusOptions = (otherOptions: CommonSelectOption[] = []): CommonSelectOption[] => {
  const { t } = useTranslation([I18N_NAMESPACES.GENERAL]);

  return [
    ...otherOptions,
    ...Object.values(USER_STATUS).map((status) => ({ value: status, label: t(`general:status-option.${status}`) })),
  ];
};

export const useCountryFunctionOptions = () => {
  const { data } = useGetCountriesFunctionsQuery();
  return data?.map((i) => ({ value: i.id, label: i.title, code: i.code })) || [];
};

export const useCountryOptions = () => {
  const { data } = useGetCountriesQuery();
  return Object.entries(data || [])?.map(([key, value]) => ({ value: key, label: value }));
};

export const useOfficeOptions = (params: GetOfficesArgs) => {
  const { data } = useGetOfficesQuery(params);
  return (
    data?.map((i) => ({
      value: i.id,
      label: i.properties.office.title,
    })) || []
  );
};

export const useEventTypeOptions = () => {
  const { data } = useGetEventTypesQuery();
  return data?.map((i) => ({ value: i.id, label: i.title }));
};

export const useEventFormatOptions = () => {
  const { data } = useGetEventFormatsQuery();
  return data?.map((i) => ({ value: i.id, label: i.title }));
};

export const useEventStatusOptions = () => {
  const { data } = useGetEventStatusesQuery();
  return data?.map((i) => ({ value: i.id, label: i.title }));
};

export const useEventTypeOfActivityOptions = () => {
  const { data } = useGetEventTypeOfActivityQuery();
  return data?.map((i) => ({ value: i.id, label: i.title }));
};

export const useEventTopicOfActivityOptions = () => {
  const { data } = useGetEventTopicOfActivityQuery();
  return data?.map((i) => ({ value: i.id, label: i.title }));
};

export const useEventFormatOfActivityOptions = () => {
  const { data } = useGetEventFormatOfActivityQuery();
  return data?.map((i) => ({ value: i.id, label: i.title }));
};

export const useEventYouthGoalOfActivityOptions = () => {
  const { data } = useGetEventYouthGoalOfActivityQuery();
  return data?.map((i) => ({ value: i.id, label: i.title }));
};

export const useProgrammeStatusOptions = (): CommonSelectOption[] => {
  const { t } = useTranslation([I18N_NAMESPACES.GENERAL]);

  return [...Object.values(PROGRAMME_STATUS).map((value) => ({ value, label: t(`general:status-option.${value}`) }))];
};

export const useBulletinCategoryOptions = () => {
  const { data } = useGetBulletinCategoriesQuery();
  return data?.map((i) => ({ value: String(i.id), label: i.title })) || [];
};

export const useProgrammeCategoryOptions = () => {
  const { data } = useGetProgrammeCategoriesQuery();
  return data?.map((i) => ({ value: String(i.id), label: i.title })) || [];
};

export const useProgrammeTargetOptions = () => {
  const { data } = useGetProgrammeTargetQuery();
  return data?.map((i) => ({ value: String(i.id), label: i.title })) || [];
};

export const useProgrammeTopicsOptions = ({ id }: { id: number | undefined }) => {
  const { data } = useGetProgrammeTopicsQuery({ id });
  return data?.map((i) => ({ value: String(i.id), label: i.title, programmeCategory: i.programmeCategory })) || [];
};

export const useProgrammeTypeOptions = () => {
  const { data } = useGetProgrammeTypeQuery();
  return data?.map((i) => ({ value: String(i.id), label: i.title })) || [];
};

export const useProgrammeTypeOfActivityOptions = (): { value: any; label: string }[] => {
  const { data } = useGetProgrammeTypeOfActivityQuery();
  return data?.map((i) => ({ value: String(i.id), label: i.title })) || [];
};
