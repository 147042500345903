import clsx from 'clsx';
import React, { CSSProperties } from 'react';

import Icon from './Icon';

import './IconCircle.css';

interface IconCircleProps {
  name: string;
  shadow?: boolean;
  size?: string | 8 | 16 | 32;
  iconSize?: string | number;
}

export const IconCircle: React.FC<IconCircleProps> = ({ name, size = 16, iconSize, shadow }) => {
  const style: CSSProperties = {};
  if (typeof size === 'string') {
    style.width = size;
    style.height = size;
  }
  return (
    <div
      className={clsx(
        'icon-circle',
        {
          shadow: shadow,
        },
        {
          'w-8 h-8': parseInt(String(size)) === 8,
          'w-16 h-16': parseInt(String(size)) === 16,
          'w-32 h-32': parseInt(String(size)) === 32,
        }
      )}
      style={style}
    >
      <Icon name={name} size={iconSize} className="text-black fill-current" />
    </div>
  );
};
