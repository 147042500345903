import clsx from 'clsx';
import React, { useCallback, useState } from 'react';

import { Image } from './Image';

import './ImageCircle.css';

interface ImageCircleProps {
  src?: string;
  alt: string;
  shadow?: boolean;
  size?: 16 | 32;
  fallbackText?: string;
}

export const ImageCircle: React.FC<ImageCircleProps> = ({ src, alt, size = 16, fallbackText, shadow }) => {
  const [showFallback, setFallback] = useState(!src);

  const handleError = useCallback(() => {
    setFallback(true);
  }, []);

  return (
    <div className={clsx('image-circle', { shadow: shadow, 'w-16 h-16': size === 16, 'w-32 h-32': size === 32 })}>
      {!showFallback ? (
        <Image src={src} alt={alt} className="w-full h-full object-cover" onError={handleError} />
      ) : (
        <span className="font-bold uppercase text-2xl tracking-wide text-secondary">{fallbackText}</span>
      )}
    </div>
  );
};
