import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';

import { isInternalLink, stripHref } from '../../utils/route';
import { CardActionProps } from './components/CardAction';
import { CardBodyProps } from './components/CardBody';
import { CardFooterProps } from './components/CardFooter';
import { CardHeadingProps } from './components/CardHeading';

import './Card.css';

type CardContent = CardBodyProps | CardHeadingProps | CardFooterProps | CardActionProps;

export interface CardProps {
  children: ReactElement<CardContent> | (ReactElement<CardContent> | undefined | null)[] | null;
  className?: string;
  href?: string;
  onClick?(): void;
}

export interface CardBaseProps {
  flat?: boolean;
  heading?: string;
  title?: string;
  description?: string;
  cardHref?: string;
  button?: {
    text?: string;
    link?: string;
  };
}

export const Card: React.FC<CardProps> = ({ children, className, href, onClick }) => {
  const BEM = clsx('card', className);

  const strippedHref = stripHref(href);

  if (href && isInternalLink(strippedHref)) {
    return (
      <NavLink to={strippedHref} className={BEM}>
        {children}
      </NavLink>
    );
  }

  if (href) {
    return (
      <a className={BEM} href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }

  if (onClick) {
    return (
      <button type="button" className={BEM} onClick={onClick}>
        {children}
      </button>
    );
  }

  return <div className={BEM}>{children}</div>;
};
