export enum PERMISSION {
  CHANGE_OWN_USERNAME = 'change own username',
}

export enum ROLE {
  ADMIN = 'administrator',
  EBL = 'ebl',
  NC = 'national_coordinator',
  MULTIPLIER = 'multiplier',
  AUTHENTICATED = 'authenticated', // should be no need to use
  ANONYMOUS = 'anonymous', // should be no need to use
}

export const MANAGER_ROLES = [ROLE.ADMIN, ROLE.EBL, ROLE.NC];
