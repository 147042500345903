import clsx from 'clsx';
import React, { useMemo } from 'react';

import { IconCircle } from '../icon/IconCircle';
import { ImageCircle } from '../image/ImageCircle';

interface AvatarProps {
  name: string;
  src?: string;
  size?: 16 | 32;
  className?: string;
}

export const Avatar: React.FC<AvatarProps> = ({ name, src, size = 16, className }) => {
  const initials = useMemo(() => {
    const fullName = (name || '').split(' ');
    return fullName
      .map((n, i) => (i === 0 || i === fullName.length - 1) && n[0])
      .filter((n) => n)
      .join('');
  }, [name]);

  return (
    <div className={clsx('avatar', className)}>
      {src || initials ? (
        <ImageCircle src={src} alt={name} size={size} fallbackText={initials} shadow />
      ) : (
        <IconCircle name="chain" size={size} shadow />
      )}
    </div>
  );
};
