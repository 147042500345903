import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { EmailCheckResponse } from './generalApi';

export interface LoginResponse {
  token_type: string;
  expires_in: number;
  access_token: string;
  refresh_token: string;
}

export interface LoginRequest {
  username: string;
  password: string;
}

export interface GoogleLoginRequest {
  idToken: string;
  googleAccessToken: string;
  googleTokenExpiresIn: string;
}

export const clientID = process.env.REACT_APP_CLIENT_ID || '';
export const googleAuthClientID = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID || '';
export const authenticationApi = createApi({
  reducerPath: 'authenticationApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL || '' }),
  endpoints: (builder) => ({
    emailCheck: builder.query<EmailCheckResponse, string>({
      query: (email) => `/api/v2/user/emailCheck?email=${email}`,
    }),
    getCSRFToken: builder.mutation<any, any>({
      query: () => '/session/token',
    }),
    refreshAccessToken: builder.mutation<LoginResponse, { refresh_token: string }>({
      query: (payload) => {
        const formData = new FormData();
        formData.append('grant_type', 'refresh_token');
        formData.append('client_id', clientID);
        formData.append('refresh_token', payload.refresh_token);

        return {
          url: '/oauth/token',
          method: 'POST',
          body: formData,
        };
      },
    }),
    loginWithEmail: builder.mutation<LoginResponse, LoginRequest>({
      query: (payload) => {
        const formData = new FormData();
        formData.append('grant_type', 'password');
        formData.append('client_id', clientID);
        formData.append('username', payload.username);
        formData.append('password', payload.password);

        return {
          url: `/oauth/token`,
          method: 'POST',
          body: formData,
        };
      },
    }),
    loginWithGoogle: builder.mutation<LoginResponse, GoogleLoginRequest>({
      query: (payload) => {
        const formData = new FormData();
        formData.append('grant_type', 'google_login_grant');
        formData.append('client_id', clientID);
        formData.append('id_token', payload.idToken);
        formData.append('google_user_id', googleAuthClientID);
        formData.append('google_access_token', payload.googleAccessToken);
        formData.append('google_token_expires_in', payload.googleTokenExpiresIn);
        formData.append('scope', 'openid profile email');

        return {
          url: '/oauth/token',
          method: 'POST',
          body: formData,
        };
      },
    }),
  }),
});

export const {
  useEmailCheckQuery,
  useRefreshAccessTokenMutation,
  useLoginWithEmailMutation,
  useLoginWithGoogleMutation,
} = authenticationApi;
