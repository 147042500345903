import React from 'react';

import { useGetSidebarIconsQuery } from '../../store/api/generalApi';
import { useAuthState, useLogout } from '../../store/auth/authHooks';
import { Button } from '../button/Button';
import { IconExit } from '../icon';
import { Image } from '../image/Image';

const Sidebar = () => {
  const logout = useLogout();
  const { allowedAppAccess } = useAuthState();

  return (
    <div className="hidden md:flex flex-col border-2 border-t-0 print:hidden">
      <div className="flex flex-col overflow-auto max-h-sidebar">{allowedAppAccess && <SidebarIcons />}</div>
      <Button variant="plain" className="mt-auto pt-8 pb-8 pr-4 pl-4 border-t-2" onClick={logout} aria-label="Log out">
        <IconExit size="1.5rem" />
      </Button>
    </div>
  );
};

const SidebarIcons = React.memo(() => {
  const { data } = useGetSidebarIconsQuery({});

  return (
    <>
      {data?.map(({ icon, title, id, links }) => (
        <Button className="p-4" variant="plain" key={id} href={links?.linkUrl}>
          <Image src={icon} alt={title} className="w-6" />
        </Button>
      ))}
    </>
  );
});

export default React.memo(Sidebar);
