import clsx from 'clsx';
import React from 'react';

import { Heading } from '../../../heading/Heading';

import './GroupCard.css';

interface GroupCardProps {
  title: string;
  text: string;
  className?: string;
}

export const GroupCard: React.FC<GroupCardProps> = React.memo(({ title, text, className }) => {
  return (
    <div
      className={clsx(
        'group-card relative flex flex-col justify-center md:shadow rounded-2xl md:mr-6 md:px-5 md:py-8 md:w-60 z-1 bg-no-repeat bg-cover',
        className
      )}
    >
      <Heading is="h3" className="md:text-black mb-4">
        {title}
      </Heading>
      <p className="hidden md:block">{text}</p>
    </div>
  );
});
