import clsx from 'clsx';
import React, { CSSProperties, FunctionComponent, SVGProps } from 'react';

import ICON_MAP from '../../assets/icons/icon-map';

interface IconProps {
  name: string;
  className?: string;
  size?: string | number;
  color?: string;
  style?: CSSProperties;
}

const Icon: React.FC<IconProps> = ({ name, className, size = '1em', color, style, ...rest }) => {
  const Icon = (ICON_MAP as { [key: string]: FunctionComponent<SVGProps<SVGSVGElement>> })[name];
  return (
    <Icon
      style={{ width: size, height: size, color: color, ...style }}
      className={clsx('fill-current', className)}
      {...rest}
    />
  );
};

export default Icon;
