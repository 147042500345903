import { ReactComponent as Archive } from './archive.svg';
import { ReactComponent as Burger } from './burger.svg';
import { ReactComponent as Chain } from './chain.svg';
import { ReactComponent as Checked } from './checked.svg';
import { ReactComponent as CircleInfo } from './circle-info.svg';
import { ReactComponent as Clock } from './clock.svg';
import { ReactComponent as Cogwheel } from './cogwheel.svg';
import { ReactComponent as Cross } from './cross.svg';
import { ReactComponent as Dots } from './dots.svg';
import { ReactComponent as Download } from './download.svg';
import { ReactComponent as Exit } from './exit.svg';
import { ReactComponent as File } from './file.svg';
import { ReactComponent as Lock } from './lock.svg';
import { ReactComponent as Magnify } from './magnify.svg';
import { ReactComponent as Move } from './move.svg';
import { ReactComponent as NotificationBell } from './notification-bell.svg';
import { ReactComponent as Pencil } from './pencil.svg';
import { ReactComponent as Picture } from './picture.svg';
import { ReactComponent as PlusBox } from './plus-box.svg';
import { ReactComponent as SelectAll } from './select-all.svg';
import { ReactComponent as Squares } from './squares.svg';
import { ReactComponent as Trashcan } from './trashcan.svg';

const ICON_MAP = {
  archive: Archive,
  burger: Burger,
  chain: Chain,
  checked: Checked,
  'circle-info': CircleInfo,
  clock: Clock,
  cogwheel: Cogwheel,
  cross: Cross,
  dots: Dots,
  download: Download,
  exit: Exit,
  file: File,
  lock: Lock,
  magnify: Magnify,
  move: Move,
  'notification-bell': NotificationBell,
  pencil: Pencil,
  picture: Picture,
  'plus-box': PlusBox,
  'select-all': SelectAll,
  squares: Squares,
  trashcan: Trashcan,
};

export default ICON_MAP;
