import clsx from 'clsx';
import React from 'react';

import { Item, ItemAside, ItemBody } from './Item';

interface NumberItemProps {
  number: number;
  title?: string;
  info?: string;
  className?: string;
  numberColor?: string; // default to text-light
}

export const NumberItem: React.FC<NumberItemProps> = (props) => {
  const { children, className, number, numberColor = 'text-light' } = props;

  return (
    <Item className={clsx('item--number p-4 md:p-0 border-2 md:border-0 rounded-lg', className)}>
      <ItemAside className="self-start">
        <span className={clsx('text-7xl font-black', numberColor)}>{number}</span>
      </ItemAside>
      <ItemBody>{children}</ItemBody>
    </Item>
  );
};
