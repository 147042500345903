import React from 'react';

import Icon from './Icon';

export const IconArchive = (props) => <Icon {...props} name="archive" />;
export const IconBurger = (props) => <Icon {...props} name="burger" />;
export const IconChain = (props) => <Icon {...props} name="chain" />;
export const IconChecked = (props) => <Icon {...props} name="checked" />;
export const IconCircleInfo = (props) => <Icon {...props} name="circle-info" />;
export const IconClock = (props) => <Icon {...props} name="clock" />;
export const IconCogwheel = (props) => <Icon {...props} name="cogwheel" />;
export const IconCross = (props) => <Icon {...props} name="cross" />;
export const IconDots = (props) => <Icon {...props} name="dots" />;
export const IconDownload = (props) => <Icon {...props} name="download" />;
export const IconExit = (props) => <Icon {...props} name="exit" />;
export const IconFile = (props) => <Icon {...props} name="file" />;
export const IconLock = (props) => <Icon {...props} name="lock" />;
export const IconMagnify = (props) => <Icon {...props} name="magnify" />;
export const IconMove = (props) => <Icon {...props} name="move" />;
export const IconNotificationBell = (props) => <Icon {...props} name="notification-bell" />;
export const IconPencil = (props) => <Icon {...props} name="pencil" />;
export const IconPicture = (props) => <Icon {...props} name="picture" />;
export const IconPlusBox = (props) => <Icon {...props} name="plus-box" />;
export const IconSelectAll = (props) => <Icon {...props} name="select-all" />;
export const IconSquares = (props) => <Icon {...props} name="squares" />;
export const IconTrashcan = (props) => <Icon {...props} name="trashcan" />;
