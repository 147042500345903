import React from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';

import { FieldLabel, FieldLabelProps } from '../field-label/FieldLabel';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';

interface DatePickerProps extends FieldLabelProps, ReactDatePickerProps<any> {
  id: string;
}

export const DatePicker = React.memo<DatePickerProps>(({ id, ...rest }) => {
  const { title, subTitle, required, touched, error, className, style, ...datePickerProps } = rest;
  return (
    <FieldLabel
      id={id}
      title={title}
      subTitle={subTitle}
      required={required}
      touched={touched}
      error={error}
      className={className}
      style={style}
    >
      <ReactDatePicker id={id} className="text-field" {...datePickerProps} />
    </FieldLabel>
  );
});
