import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch } from 'react-redux';
import thunk from 'redux-thunk';

import rootReducer from './RootReducer';
import { authenticationApi } from './store/api/authenticationApi';
import { generalApi } from './store/api/generalApi';

const rootStore = configureStore({
  reducer: rootReducer,
  middleware: [
    thunk,
    generalApi.middleware,
    authenticationApi.middleware,
    // Extra middleware
  ] as const,
});

setupListeners(rootStore.dispatch);

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./RootReducer', () => {
    const newRootReducer = require('./RootReducer').default;
    rootStore.replaceReducer(newRootReducer);
  });
}

export type RootDispatch = typeof rootStore.dispatch;
export const useRootDispatch = () => useDispatch<RootDispatch>();

export default rootStore;
