import React, { useMemo } from 'react';

import { Heading } from '../../../../components/heading/Heading';
import { IconCircle } from '../../../../components/icon/IconCircle';
import { OFFICE_STATUS, USER_STATUS } from '../../../../store/api/generalApi';
import { useAuthState } from '../../../../store/auth/authHooks';

export default function NoAccess() {
  const { user, office } = useAuthState();

  const state = useMemo(
    () =>
      user?.userStatus === USER_STATUS.PENDING
        ? {
            icon: 'clock',
            text: 'Your account is pending. Please try again later.',
          }
        : !office || [OFFICE_STATUS.DECLINED, OFFICE_STATUS.PENDING].includes(office?.status)
        ? {
            icon: 'lock',
            text: `Your account doesn't have an office selected or it's not approved`,
          }
        : {
            icon: 'lock',
            text: 'Your account is locked. For further information, please contact an admin.',
          },
    [office, user?.userStatus]
  );

  return (
    <div className="absolute left-0 top-0 right-0 bottom-0 flex justify-center items-center bg-primary-gradient">
      <div className="flex flex-col justify-center items-center">
        <IconCircle name={state.icon} iconSize="24" />
        <Heading is="h1" as="h2" className="mt-4">
          {state.text}
        </Heading>
      </div>
    </div>
  );
}
