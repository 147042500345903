import React from 'react';
import { Redirect } from 'react-router-dom';

import { ROUTES, RoutesWithComponentsProps } from '../../Routes';
import { useGetPermissionsQuery, useWhoAmIQuery } from '../../store/api/generalApi';
import { PERMISSION, ROLE } from '../../utils/permissions';
import { ROLES_MATCH_TYPE, usePermissionGuard, useRoleGuard } from '../../utils/roleGuard';

export interface GuardProps {
  matchRoles?: ROLE[];
  matchPermissions?: PERMISSION[];
  matchType?: ROLES_MATCH_TYPE;
  not?: boolean;
  includeAdmin?: boolean;
}

export const Guard: React.FC<GuardProps> = React.memo<GuardProps>(
  ({ matchRoles, matchPermissions, matchType, not, includeAdmin, children }) => {
    const rolesMatch = useRoleGuard(matchRoles, matchType, not, includeAdmin);
    const permissionMatch = usePermissionGuard(matchPermissions, matchType, not);

    return children && ((matchRoles?.length && rolesMatch) || (matchPermissions?.length && permissionMatch)) ? (
      <>{children}</>
    ) : null;
  }
);

export interface RouteGuardProps {
  route: RoutesWithComponentsProps<ROUTES>;
}

export const RouteGuard: React.FC<RouteGuardProps> = React.memo<RouteGuardProps>(({ route, ...rest }) => {
  const { isLoading: permissionsLoading } = useGetPermissionsQuery(undefined);
  const { isLoading: userLoading } = useWhoAmIQuery(undefined);
  const rolesMatch = useRoleGuard(route?.roleLock?.roles);
  const permissionMatch = usePermissionGuard(route?.permissionLock?.permissions);

  if (userLoading || permissionsLoading) {
    // still fetching guard info
    return null;
  } else if (route?.roleLock?.roles && !rolesMatch) {
    // if user doesn't have necessary roles then redirect to fallback
    return <Redirect to={route?.roleLock?.fallbackRoute || '/'} />;
  } else if (route?.permissionLock?.permissions && !permissionMatch) {
    // if route is locked behind a specific feature flag
    return <Redirect to={route?.permissionLock?.fallbackRoute || '/'} />;
  } else {
    const Comp = route.component as any;

    return <Comp {...rest} />;
  }
});
