import clsx from 'clsx';
import React, { ReactElement, useState } from 'react';

import { Heading } from '../heading/Heading';

import './Popup.css';

export interface PopupProps {
  children: (ReactElement<PopupItemProps> | undefined | null)[] | ReactElement<PopupItemProps> | undefined | null;
  className?: string;
  title?: string;
  direction: 'left' | 'right' | 'top' | 'bottom';
  location?: number;
}

export function Popup({ children, className, title, direction, location = 50 }: PopupProps) {
  const [popupState, setPopupState] = useState(false);
  const popupLocation =
    direction === 'top' || direction === 'bottom'
      ? { transform: `translateX(${location}%)` }
      : { transform: `translateY(-${Math.abs(location - 100)}%)` };
  const arrowLocation =
    direction === 'top' || direction === 'bottom'
      ? { left: `${Math.abs(location - 100)}%` }
      : { top: `${Math.abs(location - 100)}%` };

  return (
    <div className={clsx('popup-trigger')} onClick={() => setPopupState(!popupState)} role="none">
      {popupState && (
        <div className={clsx('popup-container', direction, className)} style={popupLocation}>
          <div className={clsx('popup-arrow')} style={arrowLocation}></div>
          {title && (
            <Heading is="h5" className={clsx('popup-title')}>
              {title}
            </Heading>
          )}
          {children && <div className={clsx('popup-items')}>{children}</div>}
        </div>
      )}
    </div>
  );
}

export interface PopupItemProps {
  children: React.ReactNode | React.ReactNodeArray;
  className?: string;
  href?: string;
}

export function PopupItem({ children, className, href }: PopupItemProps) {
  return href ? (
    <a className={clsx('popup-item', className)} href={href}>
      {children}
    </a>
  ) : (
    <div className={clsx('popup-item', className)}>{children}</div>
  );
}
