import clsx from 'clsx';
import React from 'react';

import './Loader.css';

interface LoaderProps {
  overlay?: boolean;
  fullPage?: boolean;
  minHeight?: 'sm' | 'md' | 'lg';
  size?: 'sm' | 'md' | 'lg';
}

export const Loader = React.memo(({ overlay, size = 'md', fullPage, minHeight }: LoaderProps) => {
  return (
    <div
      className={clsx('loader', `loader--${size}`, `loader--height-${minHeight}`, {
        'loader--overlay': overlay,
        'loader--full': fullPage,
      })}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        className="loader__svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle cx="50" cy="50" r="0" fill="none" stroke="#404040" strokeWidth="2">
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur="1s"
            values="0;40"
            keyTimes="0;1"
            keySplines="0 0.2 0.8 1"
            calcMode="spline"
            begin="0s"
          ></animate>
          <animate
            attributeName="opacity"
            repeatCount="indefinite"
            dur="1s"
            values="1;0"
            keyTimes="0;1"
            keySplines="0.2 0 0.8 1"
            calcMode="spline"
            begin="0s"
          ></animate>
        </circle>
        <circle cx="50" cy="50" r="0" fill="none" stroke="#404040" strokeWidth="2">
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur="1s"
            values="0;40"
            keyTimes="0;1"
            keySplines="0 0.2 0.8 1"
            calcMode="spline"
            begin="-0.5s"
          ></animate>
          <animate
            attributeName="opacity"
            repeatCount="indefinite"
            dur="1s"
            values="1;0"
            keyTimes="0;1"
            keySplines="0.2 0 0.8 1"
            calcMode="spline"
            begin="-0.5s"
          ></animate>
        </circle>
      </svg>
    </div>
  );
});
