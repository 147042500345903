import { ROUTES } from '../Routes';
import { beUrls } from './constants';

export const isInternalLink = (href: string): boolean => {
  const internalRoutes = Object.values(ROUTES);
  return internalRoutes.findIndex((i) => new RegExp(`^${i}`).test(href)) > -1;
};

export const stripHref = (href?: string): string => {
  const removeStrings = [window.location.origin, ...beUrls];

  // TODO ask BE to make internal links relative, so we don't have to parse BE domain from URLs
  return removeStrings.reduce((p, c) => p.replace(c, ''), href || '');
};
