import clsx from 'clsx';
import React from 'react';
import MasonryLibrary from 'react-masonry-css';

import './Masonry.css';

/* TODO: Give correct default values when template is OK */

const defaultBreakpointColumns = {
  default: 3,
  1280: 2,
  1024: 1,
  500: 1,
};

export interface IMasonryProps {
  breakpointCols?: Record<number | 'default', number>;
  className?: string;
}

export const Masonry: React.FC<IMasonryProps> = React.memo((props) => {
  const { children, className } = props;
  const { breakpointCols } = props;

  return (
    <MasonryLibrary
      breakpointCols={breakpointCols || defaultBreakpointColumns}
      className={clsx('masonry', className)}
      columnClassName="masonry__column"
    >
      {children}
    </MasonryLibrary>
  );
});
