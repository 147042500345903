import clsx from 'clsx';
import { getIn } from 'formik';
import React, { useCallback, useMemo } from 'react';
import Select, { GroupTypeBase, OptionTypeBase, Styles } from 'react-select';
import Async from 'react-select/async';
import { ActionMeta, ValueType } from 'react-select/src/types';

import { Button } from '../../button/Button';
import { FieldLabel, FieldLabelProps } from '../field-label/FieldLabel';
import { FormikField } from '../Formik';

import './SelectField.css';

const customStyles: Partial<Styles<OptionTypeBase, boolean, GroupTypeBase<any>>> = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: 'none',
    borderWidth: 2,
    borderColor: state.isFocused ? '#404040 !important' : 'rgb(161, 161, 170)',
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 16,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#8FA7D5' : state.isFocused ? '#D0DCF2' : '#fff',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#D0DCF2',
    borderRadius: 16,
    paddingLeft: 14,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
  }),
};

export interface CommonSelectOption {
  value: string;
  label: string;
}

interface SelectFieldProps {
  id: string;
  isAsync?: boolean;
  selectAll?: boolean;
}

export const SelectField: React.FC<
  SelectFieldProps &
    FieldLabelProps &
    FormikField &
    React.ComponentProps<typeof Select> &
    React.ComponentProps<typeof Async>
> = React.memo((props) => {
  const {
    id,
    title,
    subTitle,
    required,
    touched,
    isAsync,
    field,
    meta,
    form,
    error,
    className,
    style,
    selectAll,
    ...selectProps
  } = props;
  const styles = { ...customStyles, ...selectProps.styles };
  const name = selectProps?.name || field?.name || '';
  const isTouched = touched || meta?.touched || getIn(form?.touched, name);
  const isError = error || meta?.error || getIn(form?.errors, name);

  const handleChange = useCallback(
    (value: ValueType<any, any>, actionMeta: ActionMeta<any>) => {
      if (name && form?.setFieldValue) {
        form?.setFieldValue(name, value);
      }
    },
    [form, name]
  );

  const handleBlur = useCallback(() => {
    if (form?.setFieldTouched) {
      form?.setFieldTouched(name);
    }
  }, [form, name]);

  const handleSelectAll = useCallback(() => {
    form?.setFieldValue(name, selectProps?.options);
  }, [form, name, selectProps?.options]);

  const SelectElement: any = useMemo(() => (isAsync ? Async : Select), [isAsync]);

  return (
    <FieldLabel
      id={id}
      title={title}
      subTitle={subTitle}
      required={required}
      touched={isTouched}
      error={isError}
      className={className}
      style={style}
    >
      {selectAll && (
        <Button variant="plain" className="absolute right-0 mt-0" onClick={handleSelectAll}>
          Select all
        </Button>
      )}
      <SelectElement
        className={clsx('select-field', { 'select-field--invalid': isTouched && isError })}
        classNamePrefix="select-field"
        onChange={handleChange}
        onBlur={handleBlur}
        value={field?.value}
        name={name}
        {...selectProps}
        inputId={id}
        styles={styles}
      />
    </FieldLabel>
  );
});
