import clsx from 'clsx';
import React from 'react';

export interface ImageProps {
  className?: string;
  src?: string;
  alt?: string;
  onError?: () => void;
}

export const Image: React.FC<ImageProps> = ({ src, alt, onError, className }) => {
  const BEM = clsx('image', className);
  return <img loading="lazy" src={src} alt={alt} className={BEM} onError={onError} />;
};
