import dayjs from 'dayjs';

import { OfficeQueryDTO } from '../store/api/generalApi';
import { isInternalLink, stripHref } from './route';

export const parseYoutubeId = (url: string): string => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : '';
};

export const parseBase64ToDrupalFormat = (base64: string): string => {
  const splitBase64 = (base64 as string).split(',');

  return splitBase64.length > 1 ? splitBase64[1] : splitBase64[0];
};

// do some parsing of the request data
// last minute to make changes before sending data to BE
export const parseCardRequestData = (data: any): any => {
  let parsedData = { ...data };

  // since drupal requires absolute urls unless it is an internal link, we need to do some parsing
  if (data.field_link?.uri) {
    const newUrl = isInternalLink(stripHref(data.field_link?.uri))
      ? `internal:${stripHref(data.field_link?.uri)}`
      : data.field_link?.uri;

    parsedData = { ...parsedData, field_link: { ...parsedData.field_link, uri: newUrl } };
  }

  // parse out youtube video_id from the url
  if (data.field_youtube?.input) {
    parsedData = {
      ...parsedData,
      field_youtube: { ...parsedData.field_youtube, video_id: parseYoutubeId(parsedData.field_youtube?.input) },
    };
  }

  // parse date field into format that doesn't upset drupal
  if (data.field_date) {
    parsedData = {
      ...parsedData,
      field_date: dayjs(data.field_date).format('YYYY-MM-DD'),
    };
  }

  // parse the office array into array of IDs
  if (data.field_offices_to_be_visible_to?.length) {
    parsedData = {
      ...parsedData,
      field_offices_to_be_visible_to: data.field_offices_to_be_visible_to.map((i: OfficeQueryDTO) => i.id as any),
    };
  }

  // parse the office array into array of IDs
  if (data.field_offices_not_to_be_visible?.length) {
    parsedData = {
      ...parsedData,
      field_offices_not_to_be_visible: data.field_offices_not_to_be_visible.map((i: OfficeQueryDTO) => i.id as any),
    };
  }

  // parse date field into format that doesn't upset drupal
  if (data.field_visible_from) {
    parsedData = {
      ...parsedData,
      field_visible_from: dayjs(data.field_visible_from).utc().format('YYYY-MM-DDTHH:mm:ss'),
    };
  }

  // parse date field into format that doesn't upset drupal
  if (data.field_visible_until) {
    parsedData = {
      ...parsedData,
      field_visible_until: dayjs(data.field_visible_until).utc().format('YYYY-MM-DDTHH:mm:ss'),
    };
  }

  // checkbox holds the data as array but drupal needs a boolean
  if (data.field_timer?.length) {
    parsedData = {
      ...parsedData,
      field_timer: !!data.field_timer?.length as any,
    };
  } else {
    parsedData = {
      ...parsedData,
      field_visible_from: '',
      field_visible_until: '',
    };
  }

  if (typeof data.field_poll !== 'undefined') {
    parsedData = {
      title: 'Poll',
      field_poll: data.field_poll,
      field_weight: data.field_weight,
    };
  }

  return parsedData;
};
