import clsx from 'clsx';
import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import { isInternalLink, stripHref } from '../../utils/route';
import { Avatar } from '../avatar/Avatar';
import { Heading } from '../heading/Heading';
import { Item, ItemAside, ItemBody } from './Item';

interface MemberItemProps {
  name: string;
  image?: string;
  info?: string;
  className?: string;
  href?: string;
}

export const MemberItem: React.FC<MemberItemProps> = (props) => {
  const { name, image, info } = props;
  const { children, className, href } = props;
  const strippedHref = stripHref(href);

  const renderContent = useMemo(
    () => (
      <Item className={clsx('item--member', className)}>
        <ItemAside>
          <Avatar name={name} src={image} size={16} />
        </ItemAside>
        <ItemBody>
          <Heading is="h4">{name}</Heading>
          {info && <p className="text-gray-500">{info}</p>}
          {children}
        </ItemBody>
      </Item>
    ),
    [children, className, image, info, name]
  );

  return href && isInternalLink(strippedHref) ? (
    <NavLink to={strippedHref}>{renderContent}</NavLink>
  ) : href ? (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {renderContent}
    </a>
  ) : (
    renderContent
  );
};
