import clsx from 'clsx';
import React from 'react';

export interface NotificationBubbleProps {
  children: (string | JSX.Element)[] | JSX.Element | string;
  className?: string;
}

export const NotificationBubble: React.FC<NotificationBubbleProps> = ({ children, className }) => {
  const BEM = clsx(
    `bg-red-500 text-white font-normal rounded-full flex items-center justify-center h-4 min-w-4 px-0.5 text-xs`,
    className
  );
  return <div className={BEM}>{children}</div>;
};
