import { Field, FieldArray, FieldConfig } from 'formik';
import React from 'react';

import { Button } from '../../button/Button';
import { FieldLabel, FieldLabelProps } from '../field-label/FieldLabel';
import { FormikField } from '../Formik';

type MultiFieldProps = FieldLabelProps &
  FormikField &
  FieldConfig & {
    /** Values used to generate and fill input fields */
    items: any;
    /** Value of a newly created item */
    itemInitialValue: any;
    /** Props specific to input field type being used */
    componentSpecificProps?: { [key: string]: any };
    /** Property name which determines input field's current value. Default: 'value' */
    valuePropName?: string;
  };

export default function MultiField(props: MultiFieldProps) {
  const { id, title, subTitle, required, touched, error, className, style, ...rest } = props;
  const { name, component, items, itemInitialValue, componentSpecificProps, valuePropName } = rest;

  return (
    <FieldLabel
      id={id}
      title={title}
      subTitle={subTitle}
      required={required}
      touched={touched}
      error={error}
      className={className}
      style={style}
    >
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <>
            {(items?.length ? items : [itemInitialValue]).map((i: any, index: number) => (
              <div className="flex mb-6" key={index}>
                <Field
                  name={`${name}.${index}`}
                  className="flex-1 m-0 mr-4"
                  component={component}
                  onChange={(value: any) => arrayHelpers.replace(index, value)}
                  {...{ [(valuePropName || 'value') as string]: i }}
                  {...componentSpecificProps}
                />
                <Button variant="secondary" className="min-w-0 m-0" onClick={() => arrayHelpers.remove(index)}>
                  -
                </Button>
              </div>
            ))}

            <div className="flex justify-end">
              <Button variant="secondary" onClick={() => arrayHelpers.push(itemInitialValue)}>
                Add row
              </Button>
            </div>
          </>
        )}
      />
    </FieldLabel>
  );
}
