import clsx from 'clsx';
import dayjs from 'dayjs';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Logo from '../../assets/images/logo.png';
import { NAV_ROUTES } from '../../Routes';
import { useGetOfficePendingCountQuery, useGetUserPendingCountQuery } from '../../store/api/generalApi';
import { useAuthState } from '../../store/auth/authHooks';
import { selectMenuVisibility } from '../../store/general/generalSelectors';
import { toggleMenuVisibility } from '../../store/general/generalStore';
import { MANAGER_ROLES } from '../../utils/permissions';
import { useRoleGuard } from '../../utils/roleGuard';
import { Button } from '../button/Button';
import { Guard } from '../guard/GuardComponent';
import { IconBurger, IconCogwheel, IconCross, IconNotificationBell } from '../icon';
import { Image } from '../image/Image';
import { NotificationBubble } from '../notification-bubble/NotificationBubble';
import { Popup, PopupItem } from '../popup/Popup';

interface NavigationProps {
  isAuthenticated: boolean;
}

export const Navigation: React.FC<NavigationProps> = React.memo(({ isAuthenticated }) => {
  const menuVisibility = useSelector(selectMenuVisibility);
  const { allowedAppAccess } = useAuthState();
  const dispatch = useDispatch();

  const hasAccessToPendingRequests = useRoleGuard(MANAGER_ROLES);
  const { roleBasedCountry } = useAuthState();
  const { data: pendingProfiles } = useGetUserPendingCountQuery(
    { country: roleBasedCountry },
    { refetchOnMountOrArgChange: true }
  );
  const { data: pendingOffices } = useGetOfficePendingCountQuery(
    { country: roleBasedCountry },
    { refetchOnMountOrArgChange: true }
  );

  return isAuthenticated ? (
    <div className="flex border-b-2 h-14 bg-white">
      <Button
        href={NAV_ROUTES.DASHBOARD}
        variant="plain"
        className="flex flex-1 items-center justify-center md:justify-start order-2 md:order-2 print:justify-center"
      >
        <Image className="h-8" src={Logo} alt="Logo" />
      </Button>
      {isAuthenticated && (
        <React.Fragment>
          {allowedAppAccess && (
            <>
              <Guard matchRoles={MANAGER_ROLES}>
                <Button
                  href={NAV_ROUTES.SETTINGS({ child: 'overview' })}
                  className="px-2 py-4 mr-2 hidden md:block order-2 print:hidden"
                  variant="plain"
                  aria-label="Settings"
                >
                  <IconCogwheel size="1.5rem" />
                </Button>
              </Guard>
              <Button
                className="px-2 py-4 mr-2 relative order-3 md:order-3 print:hidden"
                variant="plain"
                aria-label="Notifications"
              >
                <span className="block relative">
                  <IconNotificationBell size="1.5rem" />
                  {hasAccessToPendingRequests && (pendingProfiles?.pending || pendingOffices?.pending) ? (
                    <NotificationBubble className="hidden md:flex absolute -top-1 -right-1">{`${
                      Number(pendingProfiles?.pending || 0) + Number(pendingOffices?.pending || 0)
                    }`}</NotificationBubble>
                  ) : null}
                </span>
                {hasAccessToPendingRequests && (pendingProfiles?.pending || pendingOffices?.pending) ? (
                  <Popup title="Notifications" direction="bottom" location={15} className="-mt-3">
                    {pendingProfiles?.pending ? (
                      <PopupItem href={NAV_ROUTES.PROFILE({ child: 'pending-profiles' })}>
                        {`There are ${pendingProfiles?.pending} new account requests waiting for approval`}
                        <div className={clsx('font-light text-gray-400 text-sm pt-2')}>
                          {`Today at ${dayjs().format('HH:mm')}`}
                        </div>
                      </PopupItem>
                    ) : null}
                    {pendingOffices?.pending ? (
                      <PopupItem href={NAV_ROUTES.PROFILE({ child: 'pending-offices' })}>
                        {`There are ${pendingOffices?.pending} new office requests waiting for approval`}
                        <div className={clsx('font-light text-gray-400 text-sm pt-2')}>
                          {`Today at ${dayjs().format('HH:mm')}`}
                        </div>
                      </PopupItem>
                    ) : null}
                  </Popup>
                ) : null}
              </Button>
              <Button
                className="px-2 py-4 ml-2 md:ml-0 md:mr-2 order-1 md:order-4 print:hidden"
                variant="plain"
                aria-label="Toggle menu"
                onClick={() => dispatch(toggleMenuVisibility())}
              >
                {menuVisibility ? <IconCross size="1.5rem" /> : <IconBurger size="1.5rem" />}
              </Button>
            </>
          )}
        </React.Fragment>
      )}
    </div>
  ) : null;
});
