import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface GeneralState {
  menuVisibility: boolean;
}

const generalStore = createSlice({
  name: 'general',
  initialState: {
    menuVisibility: false,
  } as GeneralState,
  reducers: {
    toggleMenuVisibility: (state, action: PayloadAction<undefined | boolean>) => ({
      ...state,
      menuVisibility: action.payload || !state.menuVisibility,
    }),
  },
});

export const { toggleMenuVisibility } = generalStore.actions;

export default generalStore.reducer;
