import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { toBase64 } from '../../../utils/file';
import { Button } from '../../button/Button';
import { Image } from '../../image/Image';

interface FileUploadProps {
  className?: string;
  image?: string;
  onChange?(file: UploadableFileDTO): void;
  alignment?: 'row' | 'col';
}

export interface UploadableFileDTO {
  content: any;
  filename?: string;
  filemime?: string;
}

// TODO handle other file types
export const FileUpload: React.FC<FileUploadProps> = React.memo(({ image, alignment = 'col', className, onChange }) => {
  const [selectedFile, setSelectedFile] = React.useState<any>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const currentImage = useMemo(() => selectedFile || image, [image, selectedFile]);

  useEffect(() => {
    setSelectedFile(null);
    if (inputRef?.current?.value) {
      inputRef.current.value = '';
    }
  }, [image]);

  const handleChange = useCallback(
    async (event: any) => {
      if (event?.target?.files.length) {
        try {
          const file = event?.target?.files[0];
          const base64 = await toBase64(file);

          setSelectedFile(base64);

          if (onChange) {
            onChange({ content: base64, filename: file.name, filemime: file.type });
          }
        } catch (e) {
          console.error('Failed to convert file to base64', e);
        }
      }
    },
    [onChange]
  );

  const handleRemove = useCallback(() => {
    setSelectedFile(null);

    if (inputRef?.current?.value) {
      inputRef.current.value = '';
    }

    if (onChange) {
      onChange({ content: '' });
    }
  }, [onChange]);

  return (
    <div
      className={clsx(
        'flex w-36 gap-4',
        { 'flex-col': alignment === 'col', 'items-center': alignment === 'row' },
        className
      )}
    >
      <div
        className={clsx('flex justify-center items-center', {
          'min-w-36 min-h-24': alignment === 'row',
          'min-h-36': alignment === 'col',
          'border-2 border-dashed': !currentImage,
        })}
      >
        {currentImage && <Image className="border-2" src={currentImage} />}
      </div>
      <input ref={inputRef} className="hidden" type="file" onChange={handleChange} />
      {currentImage ? (
        <div className="flex flex-col justify-center gap-4">
          <Button variant="secondary" onClick={() => inputRef?.current?.click()}>
            Replace photo
          </Button>
          <Button variant="danger" onClick={handleRemove}>
            Remove photo
          </Button>
        </div>
      ) : (
        <Button variant="secondary" onClick={() => inputRef?.current?.click()}>
          Upload photo
        </Button>
      )}
    </div>
  );
});
