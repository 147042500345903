import React, { useCallback, useMemo } from 'react';
import ReactQuill from 'react-quill';

import { FieldLabel, FieldLabelProps } from '../field-label/FieldLabel';
import { FormikField } from '../Formik';

import 'react-quill/dist/quill.snow.css';
import './RichTextField.css';

export type RichTextFieldProps = FieldLabelProps &
  FormikField & {
    value?: string;
    toolbar?: Array<'formatting' | 'blocks'>;
  };

export const toolbarOptions = {
  formatting: ['bold', 'italic', 'underline', { size: ['large', false] }, 'link'],
  blocks: [{ indent: '-1' }, { indent: '+1' }, { list: 'ordered' }, { list: 'bullet' }, { align: [] }],
};

export const RichTextField = React.forwardRef<any, RichTextFieldProps>((props, ref) => {
  const { title, children, subTitle, touched, error, className, style, ...rest } = props;
  const { field, meta, form, id, required, toolbar = ['formatting'], ...quillProps } = rest;

  const handleChange = useCallback(
    (content, delta, source, editor) => {
      if (field && form) {
        form.setFieldValue(field.name, content);
      }
    },
    [field, form]
  );

  const combinedToolbar = useMemo(() => {
    return [...toolbar.map((t) => toolbarOptions[t]), ['clean']];
  }, [toolbar]);

  return (
    <FieldLabel
      id={id}
      title={title}
      subTitle={subTitle}
      required={required}
      touched={touched || meta?.touched}
      error={error || meta?.error}
      className={className}
      style={style}
    >
      <ReactQuill
        ref={ref}
        id={id}
        value={field?.value}
        modules={{ toolbar: combinedToolbar }}
        {...quillProps}
        onChange={handleChange}
      />
    </FieldLabel>
  );
});
